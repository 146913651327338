import { Component, OnInit } from '@angular/core';
import { CoachService } from '../core/services/coach.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reschedulemeeting',
  templateUrl: './reschedulemeeting.component.html',
  styleUrls: ['./reschedulemeeting.component.scss']
})
export class ReschedulemeetingComponent implements OnInit {

  constructor(private cs: CoachService,private toastr: ToastrService) {
    let urlParams = new URLSearchParams(window.location.search);
    let q = urlParams.get('q')+"";
    this.cs.rescheduleToken(q).subscribe((res)=>{
      localStorage.setItem('only_token',res.data.access_token);
      localStorage.setItem('ismeetingid',res.data.meetingId);
      location.href='coaches';
    })
  }

  ngOnInit(): void {
    
  }

}
