import { Component, OnInit } from '@angular/core';
import { CoachService } from '../core/services/coach.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx'
import 'moment-timezone';

@Component({
  selector: 'app-allmeetings',
  templateUrl: './allmeetings.component.html',
  styleUrls: ['./allmeetings.component.scss']
})
export class AllmeetingsComponent implements OnInit {

  loading = true;
  pgMeeting=1;
  perpage=10;
  meetings: Array<any> = [];
  allmeetings: Array<any> = [];
  name:string= "";

  // Generate Links
  meetingLinks: number[] = [];
  currentMeeting: number = 1;

  constructor(private cs: CoachService) { }

  ngOnInit(): void {
    this.cs.getAllMeetings(this.pgMeeting,this.perpage,this.name).subscribe((result: any) => {
      this.meetings = result.data;
      console.log(this.meetings,"All Connection");
      this.meetingLinks = [];
      for (let i = 1; i <= Math.ceil(result.filteredRecords/this.perpage); i++) {
        this.meetingLinks.push(i);
      }
      setTimeout(()=> { 
        this.pgfix(1);
       }, 1);
    });
    this.cs.getAllMeetings(this.pgMeeting,20000,this.name).subscribe((result: any) => {
      this.allmeetings = result.data;
    });
  }

  pgfix(current:number){
    $(".links li").each(function(index){
      if(index>current+2 || index<current-3){
        $(".links li").eq(index).attr("style","display:none");        
      }else{
        $(".links li").eq(index).removeAttr("style");
      }
    });
  }

  copyToClipboard(text:string) {
    // Create a textarea element
    const textarea = document.createElement('textarea');
    textarea.value = "https://ribbitmeetings.azurewebsites.net/room/"+text;
    
    // Make the textarea hidden
    textarea.style.position = 'fixed';
    textarea.style.top = '-9999px';
    
    // Append the textarea to the document
    document.body.appendChild(textarea);
    
    // Select and copy the text
    textarea.select();
    document.execCommand('copy');
    
    // Remove the textarea from the document
    document.body.removeChild(textarea);
  }

  getMMDDYYYY(date: any) {
    let str = date.replace("T", " ");
    return str;
  }

  loadMeeting(current:number){
    this.currentMeeting = current;
    this.pgfix(current);
    this.cs.getAllMeetings(current,this.perpage,this.name).subscribe((result: any) => {
      this.meetings = result.data;
    }); 
  }

  converttimezone(givenTime:any){
    const localTime = moment.utc(givenTime).local();
    console.log(givenTime+"  "+localTime.format('YYYY-MM-DD HH:mm'),"TEST");
    return localTime.format('YYYY-MM-DD HH:mm');
  }

  getTimeZonelabel(){    
    var currentTimezone = moment.tz(moment.tz.guess()).format('z');
    return "("+currentTimezone+")";
  }

  exportexcel(filename:string,id:string): void {
    /* table id is passed over here */   
    let element = document.getElementById(id); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, filename+".xlsx");
  }
  
  exportjsontoexcel(filename:string,data:any){
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, filename+".xlsx", { compression: true });
  }

  search(){
    let name = "";
    if($(".name").val()!=""){
      this.name = $(".name").val()+"";
    }
    if(this.name!=""){
      this.ngOnInit();
    }
  }

  reset(){
    this.name = "";
    this.ngOnInit();
    $(".name").val("");
  }

  getStartTime(starttime: string) {
    const theTime = moment.utc(starttime).subtract(1, 'hours').local().format("YYYY/MM/DD: hh:mm A");
    return (theTime);
  }
}
